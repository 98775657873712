import { Button, ChakraProvider, FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import React from "react"

interface ICreateModal {
	isOpen: boolean,
    onClose: () => void,
    handleCreate: () => void,
	setData: React.Dispatch<React.SetStateAction<{
		isOpen: boolean,
		data: {
			name: string
		}
	}>>,
    data: {
		name: string
	}
}

const CreateSubcategoryModal = (props: ICreateModal) => {
	return(
		<ChakraProvider>
				<Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
					<ModalOverlay />
					<ModalContent className="dark:bg-navy-800">
						<ModalHeader className="dark:text-white">Nova subcategoria</ModalHeader>
						<ModalCloseButton className="dark:text-white" />
						<ModalBody className="dark:text-white">
							<FormControl className="w-full">
								<FormLabel className="text-gray-600 dark:text-white">Nome</FormLabel>
								<input type="text" defaultValue={props.data.name} onChange={(e)=> {
									props.setData((val) => {
										return {...val, data: {...val.data, name: e.target.value}}
                                    })
								}} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="Nome da subcategoria" />
							</FormControl>
						</ModalBody>

						<ModalFooter>
							<Button colorScheme='red' mr={3} onClick={props.onClose}>
								Fechar
							</Button>
							<Button onClick={props.handleCreate} colorScheme="green" variant='solid'>Criar</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</ChakraProvider>
	)
}

export default CreateSubcategoryModal
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, FormControl, FormLabel, Input, Select, Tooltip } from "@chakra-ui/react"
import InputField from "components/fields/InputField"
import SelectField from "components/fields/SelectField"
import TooltipHorizon from "components/tooltip"
import { useEffect, useState } from "react"
import { MdDelete } from "react-icons/md"
import ParamsTable from "./components/ParamsTable"
import { ItemParam, OrderCategory, OrderItem, OrderSubcategory, PDFPage } from "types/app"
import api from "services/api"
import { useAppSelector } from "redux/hooks"
import { toast } from "react-toastify"
import Swal from "sweetalert2"
import MoneyInput from "components/fields/MoneyInput"
import { formatToCurrency, normalizar } from "helpers/conversions"
import { BsFloppy, BsPencil, BsTrash } from "react-icons/bs"
import CreateCategoryModal from "./components/CreateCategoryModal"
import CreateSubcategoryModal from "./components/CreateSubCategoryModal"
import { selectSubcategory } from "redux/reducers/orderSlice"
import CreateItemModal from "./components/CreateItemModal"
import CurrencyInput from "views/contracts/new/components/CurrencyInput"
import SubItemModal from "./components/SubItemModal"
import { ErrorData } from "types/fetch"

interface ISelectedCategory {
	id: number,
    name: string,
}
interface ISelectedSubcategory {
	id: number,
    name: string,
	timeout?: NodeJS.Timeout
}
interface ISelectedItem {
	id: number,
    name: string,
    price: number,
	partNumber: string,
	type: "list" | "number" | "",
	timeout?: NodeJS.Timeout
}
export interface IParamsModal {
	isOpen: boolean,
	data:{
		id?: number,
		name: string,
        price: number,
		partNumber?: string,
	}
}

const Categorys = () => {

    const [data, setData] = useState<OrderCategory[]>([])
    const user = useAppSelector((state) => state.user.user)
    const darkmode = useAppSelector((state) => state.layout.darkMode)
    const [selectedCategory, setSelectedCategory] = useState<ISelectedCategory>(undefined)
    const [selectedSubcategory, setSelectedSubcategory] = useState<ISelectedSubcategory>(undefined)
    const [searchSubInput, setSearchSubInput] = useState<{ value: string, typing: boolean, timeout?: number, debounce?: number}>({
        value: "",
        typing: false,
    })

    const [subCategories, setSubcategories] = useState<{
        all: OrderSubcategory[],
		filtered: OrderSubcategory[]
	}>({
        all: [],
		filtered: [],
	})

    const [selectedItem, setSelectedItem] = useState<ISelectedItem>(undefined)
    const [searchItemInput, setSearchItemInput] = useState<{ value: string, typing: boolean, timeout?: number, debounce?: number}>({
        value: "",
        typing: false,
    })
    const [itens, setItens] = useState<{
		all: OrderItem[],
		filtered: OrderItem[]
	}>({
		all: [],
		filtered: [],
	})
    const [params, setParams] = useState<{
		all: ItemParam[],
		filtered: ItemParam[]
	}>({
		all: [],
		filtered: [],
	})

	const [createCategoryModal, setCreateCategoryModal] = useState({
		isOpen: false,
		data: {
			name: ""
		}
	})
	const [createSubCategoryModal, setCreateSubCategoryModal] = useState({
		isOpen: false,
		data: {
			name: ""
		}
	})
	const [createItemModal, setCreateItemModal] = useState({
		isOpen: false,
		data: {
			name: ""
		}
	})
	const [createParamModal, setCreateParamModal] = useState<IParamsModal>({
		isOpen: false,
        data: {
            name: "",
            price: 0
        }
	})
	const [editParamModal, setEditParamModal] = useState<IParamsModal>({
		isOpen: false,
        data: {
			id: undefined,
            name: "",
            price: 0
        }
	})

	const resetCategory = () => {
		setSelectedCategory(undefined)
		setSubcategories({
            all: [],
            filtered: [],
        })
		setItens({
			all: [],
            filtered: [],
		})
	}
    const resetSelects = () => {
        setSelectedSubcategory(undefined)
        setSelectedItem(undefined)

		setSearchItemInput({
			value: "",
			typing: false,
            timeout: undefined,
			debounce: undefined,
		})
		setSearchSubInput({
			value: "",
			typing: false,
            timeout: undefined,
			debounce: undefined,
		})
		if(searchSubInput.timeout){
			clearTimeout(searchSubInput.timeout)
		}
		if(searchItemInput.timeout){
			clearTimeout(searchItemInput.timeout)
		}
    }
	const resetItemSelect = () => {
        setSelectedItem(undefined)
	}
    const resetItem = () => {
        setSelectedItem(undefined)
        setSearchItemInput({
            value: "",
            typing: false,
            timeout: undefined,
            debounce: undefined,
        })
		if(searchItemInput.timeout){
			clearTimeout(searchItemInput.timeout)
		}
    }

    const getCategorys = () => {
        api.get("/orders/categorys", {
            headers: {
                token: user?.token
            }
        }).then((res: {data: OrderCategory[]})=> {
            setData(res.data)
            if(res.data.length > 0){
                setSelectedCategory({
					id: res.data[0].id,
					name: res.data[0].name,
				})
				setSubcategories({
					all: res.data[0].subcategorys,
					filtered: res.data[0].subcategorys
				})
            }
        }).catch((error)=> {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const handleSubSearch = (value: string) => {
        changeSubInputVal("value", value)
		changeSubInputVal("typing", true)

		if(searchSubInput.timeout){
			clearTimeout(searchSubInput.timeout)
		}
		if(searchSubInput.debounce) {
			clearTimeout(searchSubInput.debounce)
		}

        const tO = setTimeout(() => {
			setSubcategories({
				...subCategories,
				filtered: subCategories.all.filter((subcategory) => normalizar(subcategory.name).toLowerCase().includes(normalizar(value).toLowerCase())),
			})
			const debounce = setTimeout(()=> {
				changeSubInputVal("typing", false)
				changeSubInputVal("debounce", undefined)
			}, 500)
			changeSubInputVal("debounce", debounce)
			changeSubInputVal("timeout", undefined)
        }, 500)
        changeSubInputVal("timeout", tO)
    }
    const handleItemSearch = (value: string) => {
        changeItemInputVal("value", value)
		changeItemInputVal("typing", true)

		if(searchSubInput.timeout){
			clearTimeout(searchSubInput.timeout)
		}
		if(searchSubInput.debounce) {
			clearTimeout(searchSubInput.debounce)
		}

        const tO = setTimeout(() => {
			setItens({
				...itens,
				filtered: itens.all.filter((item) => normalizar(item.name).toLowerCase().includes(normalizar(value).toLowerCase())),
			})
			const debounce = setTimeout(()=> {
				changeItemInputVal("typing", false)
				changeItemInputVal("debounce", undefined)
			}, 500)
			changeItemInputVal("debounce", debounce)
			changeItemInputVal("timeout", undefined)
        }, 500)
        changeItemInputVal("timeout", tO)
    }

    const changeSubInputVal = (key: keyof typeof searchSubInput, value: any) => {
        setSearchSubInput((prev) => {
			return {
				...prev,
				[key]: value,
			}
		})
    }
    const changeItemInputVal = (key: keyof typeof searchItemInput, value: any) => {
        setSearchItemInput((prev) => {
			return {
				...prev,
				[key]: value,
			}
		})
    }

	const handleSelectSub = (id: number) => {
        resetItem()
		const tO = setTimeout(()=> {
			setSelectedSubcategory((val)=> {
				return {
					...val,
					timeout: undefined
				}
			})
		}, 1000)

		setSelectedSubcategory({
			id: id,
			name: subCategories.all.find(s=> s.id == id)?.name || "",
			timeout: tO
		})

        setItens({
            all: subCategories.all.find(s=> s.id == id)?.items || [],
            filtered: subCategories.all.find(s=> s.id == id)?.items || []
        })
	}
	const handleSelectItem = (id: number) => {
		const tO = setTimeout(()=> {
			setSelectedItem((val)=> {
				return {
					...val,
					timeout: undefined
				}
			})
		}, 1000)


		setSelectedItem({
			id: id,
			name: itens.all.find(i => i.id == id)?.name || "",
            price: itens.all.find(i => i.id == id)?.price || 0,
			type: itens.all.find(i => i.id == id)?.type,
			timeout: tO,
			partNumber: itens.all.find(i => i.id == id)?.partNumber
		})

		setParams({
			all: itens.all.find(i => i.id == id)?.params || [],
            filtered: itens.all.find(i => i.id == id)?.params || []
		})
	}

	const handleCreateCategory = () => {
        api.post("/orders/category", {
			name: createCategoryModal.data.name
		}, {
            headers: {
                token: user?.token
            }
        }).then((res)=> {
			if(res){
				if(res.data){
					toast("Categoria criada com sucesso", {
						autoClose: 1000,
						type: "success",
						theme: darkmode ? "dark" : "light"
					})

					setData((d)=> {
						return [...d, res.data]
					})
					setCreateCategoryModal({ isOpen: false, data: {name: ""} })
				}
			}
        }).catch(error=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
	}
	const handleCreateSubCategory = () => {
        api.post("/orders/subcategory", {
            categoryId: selectedCategory.id,
			name: createSubCategoryModal.data.name
        }, {
            headers: {
                token: user?.token
            }
        }).then((res)=> {
			if(res){
				if(res.data){
					toast("Subcategoria criada com sucesso", {
						autoClose: 1000,
						type: "success",
						theme: darkmode ? "dark" : "light"
					})
					setSubcategories(s=> {
						return {
							all: [...s.all, res.data],
							filtered: [...s.filtered, res.data],
						}
					})
				}
			}
			setCreateSubCategoryModal({ isOpen: false, data: {name: ""} })
        }).catch(error=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
	}
	const handleCreateItem = () => {
        api.post("/orders/item", {
            subcategoryId: selectedSubcategory.id,
			name: createItemModal.data.name,
        }, {
            headers: {
                token: user?.token
            }
        }).then((res)=> {
			if(res){
				if(res.data){
					toast("Item criado com sucesso", {
						autoClose: 1000,
						type: "success",
						theme: darkmode ? "dark" : "light"
					})

					setItens(i=> {
						return {
							all: [...i.all, res.data],
							filtered: [...i.filtered, res.data],
						}
					})

					setCreateItemModal({ isOpen: false, data: {name: ""} })
				}
			}
        }).catch(error=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
	}
	const handleCreateParam = () => {
		api.post('/orders/param', {
			itemId: selectedItem.id,
			name: createParamModal.data.name,
			price: createParamModal.data.price,
			partNumber: createParamModal.data.partNumber
		  }, {
			headers: {
			  token: user?.token
			}
		  }).then(res=> {
			if(res){
				if(res.data){
					const {item, ...param} = res.data;
					toast("Parâmetro adicionado com sucesso", {
						type: "success",
						theme: darkmode ? "dark" : "light"
					})

					const _data = itens
					const idxAll = _data.all.findIndex(d=> d.id == item?.id)
					const idxFiltered = _data.filtered.findIndex(d=> d.id == item?.id)
					if(idxAll !== undefined){
						_data.all[idxAll] = item
					}
					if(idxFiltered !== undefined){
						_data.filtered[idxFiltered] = item
					}

					setItens({..._data})

					setParams(i=> {
						return {
							all: [...i.all, param],
							filtered: [...i.filtered, param],
						}
					})

					setCreateParamModal({ isOpen: false, data: {name: "", price: 0} })
				}
			}
		  }).catch((error: ErrorData)=>{
			  toast(error.response.data.error, {
				  type: "error",
				  theme: darkmode ? "dark" : "light"
			  })
		  })
	}

	const handleSaveCategory = () => {
		api.put("/orders/category/"+selectedCategory.id, {
            name: selectedCategory.name,
        }, {
            headers: {
                token: user?.token
            }
        }).then((res)=> {
            toast("Categoria salva com sucesso!", {
                autoClose: 1000,
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
			if(res){
				if(res.data){
					const _data = data
					const idx = _data.findIndex(d=> d.id == res.data?.id)
					if(idx !== undefined){
						_data[idx] = res.data
					}
					setData([..._data])
				}
			}

			setCreateCategoryModal({ isOpen: false, data: {name: ""} })
        }).catch(error=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
	}
	const handleSaveSubcategory = () => {
		api.put("/orders/subcategory/"+selectedSubcategory.id, {
            name: selectedSubcategory.name,
        }, {
            headers: {
                token: user?.token
            }
        }).then((res)=> {
            toast("Subcategoria salva com sucesso!", {
                autoClose: 1000,
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
			if(res){
				if(res.data){
					const _data = subCategories
					const idxAll = _data.all.findIndex(d=> d.id == res.data?.id)
					const idxFiltered = _data.filtered.findIndex(d=> d.id == res.data?.id)
					if(idxAll !== undefined){
						_data.all[idxAll] = res.data
					}
					if(idxFiltered !== undefined){
						_data.filtered[idxFiltered] = res.data
					}
					setSubcategories({..._data})
				}
			}

			setCreateCategoryModal({ isOpen: false, data: {name: ""} })
        }).catch(error=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
	}
	const handleSaveItem = () => {
		api.put("/orders/item/"+selectedItem.id, {
            name: selectedItem.name,
            type: selectedItem.type,
            price: selectedItem.price,
			partNumber: selectedItem.partNumber
        }, {
            headers: {
                token: user?.token
            }
        }).then((res)=> {
            toast("Item salvo com sucesso!", {
                autoClose: 1000,
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
			if(res){
				if(res.data){
					const _data = itens
					const idxAll = _data.all.findIndex(d=> d.id == res.data?.id)
					const idxFiltered = _data.filtered.findIndex(d=> d.id == res.data?.id)
					if(idxAll !== undefined){
						_data.all[idxAll] = res.data
					}
					if(idxFiltered !== undefined){
						_data.filtered[idxFiltered] = res.data
					}
					setItens({..._data})
				}
			}

			setCreateCategoryModal({ isOpen: false, data: {name: ""} })
        }).catch(error=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
	}
	const handleEditParam = () => {
		api.put("/orders/param/"+editParamModal.data.id, {
            name: editParamModal.data.name,
            price: editParamModal.data.price,
			partNumber: editParamModal.data.partNumber
        }, {
            headers: {
                token: user?.token
            }
        }).then((res)=> {
            toast("SubItem salvo com sucesso!", {
                autoClose: 1000,
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
			if(res){
				if(res.data){
					const _data = itens

					const idxAll = _data.all.findIndex(d=> d.id == res.data?.item?.id)
					const idxFiltered = _data.filtered.findIndex(d=> d.id == res.data?.item?.id)

					if(idxAll !== undefined){
						_data.all[idxAll] = res.data?.item
					}
					if(idxFiltered !== undefined){
						_data.filtered[idxFiltered] = res.data?.item
					}

					setItens({..._data})

					const _params = params
					const idxAllParam = _params.all.findIndex(p=> p.id == editParamModal.data.id)
					const idxFilteredParam = _params.filtered.findIndex(p=> p.id == editParamModal.data.id)

					if(idxAll !== undefined){
						_params.all[idxAllParam] = {
							..._params.all[idxAllParam],
							price: res.data.price,
							name: res.data.name,
							partNumber: res.data.partNumber
						}
					}
					if(idxFiltered !== undefined){
						_params.filtered[idxFilteredParam] = {
							..._params.all[idxAllParam],
							price: res.data.price,
							name: res.data.name,
							partNumber: res.data.partNumber
						}
					}

					setParams({..._params})

					setEditParamModal({
						isOpen: false,
                        data: {name: "", price: 0}
					})
				}
			}

			setCreateCategoryModal({ isOpen: false, data: {name: ""} })
        }).catch(error=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
	}

	const handleDeleteCategory = () => {
		Swal.fire({
            title: "Remover Categoria?",
            text: "Você realmente deseja remover a categoria "+selectedCategory.name+"?",
            icon: "question",
            confirmButtonText: "Sim",
            showCancelButton: true,
            cancelButtonText: "Não",
            background: darkmode ? "rgb(11 20 55)" : "#fff",
            color: darkmode ? "#fff" : "#222",
          }).then(response=> {
            if(response.isConfirmed){
                api.delete('/orders/category/'+selectedCategory.id, {
                    headers: {
                    token: user?.token
                    }
                }).then(()=>{
					resetCategory()
					resetSelects()
					setData(d=> {
						return [...d.filter(d=> d.id != selectedCategory.id)]
					})
                    toast("Categoria removida com sucesso!", {
                        type: "success",
                        theme: darkmode ? "dark" : "light"
                    })
                }).catch((error)=>{
                    toast(error.response.data.error, {
                        type: "error",
                        theme: darkmode ? "dark" : "light"
                    })
                })
            }
        })
	}
	const handleDeleteSubcategory = () => {
		Swal.fire({
            title: "Remover Subcategoria?",
            text: "Você realmente deseja remover a subcategoria "+selectedSubcategory.name+"?",
            icon: "question",
            confirmButtonText: "Sim",
            showCancelButton: true,
            cancelButtonText: "Não",
            background: darkmode ? "rgb(11 20 55)" : "#fff",
            color: darkmode ? "#fff" : "#222",
          }).then(response=> {
            if(response.isConfirmed){
                api.delete('/orders/subcategory/'+selectedSubcategory.id, {
                    headers: {
                    	token: user?.token
                    }
                }).then(()=>{
					resetSelects()
					setSubcategories(s=> {
						return {
                            all: s.all.filter(d=> d.id!= selectedSubcategory.id),
                            filtered: s.filtered.filter(d=> d.id!= selectedSubcategory.id),
                        }
					})
                    toast("Subcategoria removida com sucesso!", {
                        type: "success",
                        theme: darkmode ? "dark" : "light"
                    })
                }).catch((error)=>{
                    toast(error.response.data.error, {
                        type: "error",
                        theme: darkmode ? "dark" : "light"
                    })
                })
            }
        })
	}
	const handleDeleteItem = () => {
		Swal.fire({
            title: "Remover item?",
            text: "Você realmente deseja remover o item "+selectedItem.name+"?",
            icon: "question",
            confirmButtonText: "Sim",
            showCancelButton: true,
            cancelButtonText: "Não",
            background: darkmode ? "rgb(11 20 55)" : "#fff",
            color: darkmode ? "#fff" : "#222",
          }).then(response=> {
            if(response.isConfirmed){
                api.delete('/orders/item/'+selectedItem.id, {
                    headers: {
                    	token: user?.token
                    }
                }).then(()=>{
					resetItemSelect()
					setItens(i=> {
						return {
                            all: i.all.filter(d=> d.id!= selectedItem.id),
                            filtered: i.filtered.filter(d=> d.id!= selectedItem.id),
                        }
					})
                    toast("Item removido com sucesso!", {
                        type: "success",
                        theme: darkmode ? "dark" : "light"
                    })
                }).catch((error)=>{
                    toast(error.response.data.error, {
                        type: "error",
                        theme: darkmode ? "dark" : "light"
                    })
                })
            }
        })
	}
	const handleDeleteParam = (param: ItemParam) => {
		Swal.fire({
            title: "Remover subitem?",
            text: "Você realmente deseja remover o subitem "+param.name+"?",
            icon: "question",
            confirmButtonText: "Sim",
            showCancelButton: true,
            cancelButtonText: "Não",
            background: darkmode ? "rgb(11 20 55)" : "#fff",
            color: darkmode ? "#fff" : "#222",
          }).then(response=> {
            if(response.isConfirmed){
                api.delete('/orders/param/'+param.id, {
                    headers: {
                    	token: user?.token
                    }
                }).then(()=>{
					const _itens = itens
					_itens.all = _itens.all.map(i=> {
						if(i.id == param.itemId){
							i.params = i.params.filter(p=> p.id!= param.id)
						}
						return i
					})

					_itens.filtered = _itens.filtered.map(i=> {
						if(i.id == param.itemId){
							i.params = i.params.filter(p=> p.id!= param.id)
						}
						return i
					})

					setItens({..._itens})

					setParams(i=> {
						return {
                            all: i.all.filter(d=> d.id!= param.id),
                            filtered: i.filtered.filter(d=> d.id!= param.id),
                        }
					})

					toast("Subitem removido com sucesso!", {
                        type: "success",
                        theme: darkmode ? "dark" : "light"
                    })

                }).catch((error)=>{
                    toast(error.response.data.error, {
                        type: "error",
                        theme: darkmode ? "dark" : "light"
                    })
                })
            }
        })
	}

    useEffect(()=> {
        getCategorys()
    }, [])
    return (
        <>
			<CreateCategoryModal data={createCategoryModal.data} setData={setCreateCategoryModal} handleCreate={handleCreateCategory} isOpen={createCategoryModal.isOpen} onClose={() => {
				setCreateCategoryModal({ isOpen: false, data: {name: ""} })
			}} />
			<CreateSubcategoryModal data={createSubCategoryModal.data} setData={setCreateSubCategoryModal} handleCreate={handleCreateSubCategory} isOpen={createSubCategoryModal.isOpen} onClose={() => {
				setCreateSubCategoryModal({ isOpen: false, data: {name: ""} })
			}} />
			<CreateItemModal data={createItemModal.data} setData={setCreateItemModal} handleCreate={handleCreateItem} isOpen={createItemModal.isOpen} onClose={() => {
				setCreateItemModal({ isOpen: false, data: {name: ""} })
			}} />
			<SubItemModal title="Criar novo subitem" data={createParamModal.data} setData={setCreateParamModal} handleCreate={handleCreateParam} isOpen={createParamModal.isOpen} onClose={() => {
				setCreateParamModal({ isOpen: false, data: {name: "", price: 0} })
			}} />
			<SubItemModal saveButtonText="Salvar" title="Editar subitem" data={editParamModal.data} setData={setEditParamModal} handleCreate={handleEditParam} isOpen={editParamModal.isOpen} onClose={() => {
				setEditParamModal({ isOpen: false, data: {name: "", price: 0} })
			}} />

			<div className="grid grid-cols-8 justify-start border-b-2 bg-gray-800/90 w-full">
				{data?.sort((a, b)=> a.name > b.name ? 1 : a.name < b.name ? -1 : 0).map((categoria) => (
					<button onClick={() => {
                        resetSelects()
						setSubcategories({
							all: categoria.subcategorys,
							filtered: categoria.subcategorys
						})
                        setSelectedCategory({
							id: categoria.id,
							name: categoria.name,
						})
                    }} className={`text-xs w-full h-[65px] flex-wrap px-5 break-all text-white font-bold hover:bg-gray-800/50 ${selectedCategory?.id == categoria.id ? "!bg-gray-800" : ""}`} title={categoria.name}>
						<span className={`line-clamp-1`}>
							{categoria.name}
						</span>
					</button>
				))}
				<button className="text-md w-[50px] h-[65px] flex-wrap p-2 font-bold text-white bg-green-700" onClick={() => {
                    setCreateCategoryModal({ isOpen: true, data: { name: ""} })
				}}>+</button>
			</div>

            <div className="grid grid-cols-3 gap-5 p-2 md:p-5">
				<div className="flex flex-col gap-2 p-2 min-h-[450px] max-h-[450px] border-gray-600 border-r">
					<FormControl className="w-full flex gap-1 flex-col">
						<FormLabel className="font-bold text-sm dark:text-white">Nome</FormLabel>
						<div className="flex items-center gap-2">
							<Input type="text" value={selectedCategory?.name || ""} onChange={e=> {
								setSelectedCategory(c=> {
									return {...c, name: e.target.value }
								})
							}} className="h-[50px] px-2 border border-gray-600 w-full" placeholder="Nome da subcategoria" />
							<button onClick={handleSaveCategory} className="bg-green-500 hover:bg-green-600 text-white rounded w-[75px] h-[50px] flex items-center justify-center text-xs">
								<div className="flex items-center gap-1">
									Salvar
								</div>
							</button>
							<button onClick={handleDeleteCategory} className="bg-red-500 hover:bg-red-600 text-white rounded w-[75px] h-[50px] flex items-center justify-center text-xs">
								<div className="flex items-center gap-1">
									Remover
								</div>
							</button>
						</div>
					</FormControl>
					<div className="flex flex-col w-full items-start gap-2">
						<div className="flex items-center justify-between w-full">
							<span className="font-bold text-sm dark:text-white">Subcategorias</span>
							<button onClick={()=> {
								setCreateSubCategoryModal({
									isOpen: true,
                                    data: { name: "" }
								})
							}} className="p-2 ml-auto text-left text-sm bg-orange-500 hover:bg-orange-600 text-white rounded">+ Nova subcategoria</button>
						</div>
						<input type="text" onChange={(e)=> {
							handleSubSearch(e.target.value);
						}} className="w-full h-[40px] p-2 mb-2 border border-gray-700 rounded" placeholder="Buscar" />

						{searchSubInput.typing ?
							<span>Buscando...</span>
						:
						<div className="max-h-[240px] overflow-y-auto">
							{subCategories?.filtered?.length > 0 ? subCategories.filtered.sort((a, b)=> a.id > b.id ? 1 : a.id < b.id ? -1 : 0).map(s => (
								<button onClick={() => {
									handleSelectSub(s.id)
								}} className={ `p-2 w-full text-left text-sm hover:bg-gray-100 ${selectedSubcategory?.id == s.id ? "!bg-gray-800/90 text-white" : ""}`}>{s.name}</button>
							)) : <span>Sem registros</span>}
						</div>
						}
					</div>
				</div>

				<div className="flex flex-col gap-2 p-2 h-full border-gray-600 border-r">
					{selectedSubcategory ?
						selectedSubcategory.timeout ?
							<span>Carregando...</span>
						:
							<div className="flex flex-col gap-2">
								<FormControl className="w-full flex gap-1 flex-col">
									<FormLabel className="font-bold text-sm dark:text-white">Nome</FormLabel>
									<div className="flex items-center gap-2">
										<Input type="text" defaultValue={subCategories?.all?.find(s=> s.id == selectedSubcategory?.id)?.name || ""} onChange={e=> {
											setSelectedSubcategory(s=> {
												return {...s, name: e.target.value }
											})
										}} className="h-[50px] px-2 border border-gray-600 w-full" placeholder="Nome da subcategoria" />
										<button onClick={() => {
											handleSaveSubcategory()
										}} className="bg-green-500 hover:bg-green-600 text-white rounded w-[75px] h-[50px] flex items-center justify-center text-xs">
											<div className="flex items-center gap-1">
												Salvar
											</div>
										</button>

										<button onClick={handleDeleteSubcategory} className="bg-red-500 hover:bg-red-600 text-white rounded w-[75px] h-[50px] flex items-center justify-center text-xs">
											<div className="flex items-center gap-1">
												Remover
											</div>
										</button>
									</div>
								</FormControl>

                                <div className="flex items-center justify-between">
                                    <span className="font-bold text-sm dark:text-white">Itens</span>
                                    <button onClick={()=> {
										setCreateItemModal(i=> {
											return {...i, isOpen: true, data: { name: "" } }
										})
                                    }} className="p-2 ml-auto text-left text-sm bg-orange-500 hover:bg-orange-600 text-white">+ Novo item</button>
                                </div>


                                <div className="flex flex-col w-full items-start border-gray-600">
                                    <input type="text" onChange={(e)=> {
                                        handleItemSearch(e.target.value);
                                    }} className="w-full h-[40px] p-2 mb-2 border border-gray-700 rounded" placeholder="Buscar" />

                                    {searchItemInput.typing ?
                                        <span>Buscando...</span>
                                    :
                                    <div className="max-h-[240px] overflow-y-auto w-full pr-2">
                                        {itens?.filtered?.length > 0 ? itens.filtered.sort((a, b)=> a.id > b.id ? 1 : a.id < b.id ? -1 : 0).map(s => (
                                            <button onClick={() => {
                                                handleSelectItem(s.id)
                                            }} className={ `p-2 w-full text-left text-sm hover:bg-gray-100 ${selectedItem?.id == s.id ? "!bg-gray-800/90 text-white" : ""}`}>{s.name}</button>
                                        )) : <span>Sem registros</span>}
                                    </div>
                                    }
                                </div>
							</div>
					:
						<div className="flex h-full w-full">
                            <p className="text-sm">Selecione uma subcategoria.</p>
                        </div>
					}
				</div>

				<div className="flex flex-col gap-2 p-2 h-full border-gray-600 border-r">
					{selectedItem?.id ?
						selectedItem.timeout ?
							<span>Carregando...</span>
						:
							<div className="flex flex-col gap-2">
								<FormControl className="w-full flex gap-1 flex-col">
									<FormLabel className="font-bold text-sm dark:text-white">Nome</FormLabel>
									<Input type="text" defaultValue={selectedItem?.name || ""} onChange={(e) => {
										setSelectedItem(i=> {
											return {...i, name: e.target.value }
										})
									}} className="h-[50px] px-2 border border-gray-600 w-full" placeholder="Nome da subcategoria" />
								</FormControl>

								<div className="flex gap-2">
									<FormControl className="w-full flex gap-1 flex-col">
										<FormLabel className="font-bold text-sm pl-2 dark:text-white">Tipo</FormLabel>
											<Select defaultValue={selectedItem.type} icon={<></>} onChange={e=> {
												setSelectedItem((i) => {
													return {...i, type: e.target.value as ISelectedItem["type"] }
												})
											}} className="h-[50px] px-2 border border-gray-600 w-full">
												<option value={"list"}>Lista</option>
												<option value={"number"}>Quantidade</option>
											</Select>
									</FormControl>

									{selectedItem.type != "list" &&
										<FormControl className="w-full flex gap-1 flex-col">
											<FormLabel className="font-bold text-sm pl-2 dark:text-white">Part Number</FormLabel>
											<Input type="text" defaultValue={selectedItem?.partNumber || ""} onChange={(e) => {
												setSelectedItem(i=> {
													return {...i, partNumber: e.target.value }
												})
											}} className="h-[50px] px-2 border border-gray-600 w-full" placeholder="PN" />
										</FormControl>
									}
								</div>

								{selectedItem.type == "list"
								?
									<>
										<div className="flex items-center justify-between">
											<span className="font-bold text-sm dark:text-white">Subitens</span>
											<button onClick={()=> {
												setCreateParamModal(() => {
													return {
														isOpen: true,
														data: { name: "", price: 0 }
													}
												})
											}} className="p-2 ml-auto text-left text-sm bg-orange-500 hover:bg-orange-600 text-white">+ Novo subitem</button>
										</div>


										<div className="flex flex-col w-full items-start border-gray-600 h-[200px] max-h-[200px] overflow-y-auto">
											<input type="text" onChange={(e)=> {
												handleItemSearch(e.target.value);
											}} className="w-full h-[40px] p-2 mb-2 border border-gray-700 rounded" placeholder="Buscar" />

											{searchItemInput.typing ?
												<span>Buscando...</span>
											:
											<div className="max-h-[160px] w-full overflow-y-auto pr-2">
												{params?.filtered?.length > 0 ? params.filtered.sort((a, b)=> a.id > b.id ? 1 : a.id < b.id ? -1 : 0).map(p => (
													<div className={`grid grid-cols-7 hover:bg-gray-100 items-center w-full`}>
														<span className={ `p-2 w-full text-left text-sm col-span-4 `}>{p.name}</span>
														<span className={ `p-2 w-full text-left text-sm col-span-2`}>{formatToCurrency(p.price)}</span>
														<div className="flex items-center gap-1">
															<button onClick={() => {
																setEditParamModal({
																	isOpen: true,
                                                                    data: p
																})
															}} title={"Editar"} className={ `p-2 text-left text-sm col-span-1 text-blue-500 bg-white hover:bg-blue-600 h-[28px] w-[28px] hover:text-white transition flex items-center justify-center rounded`}>
																<BsPencil size={16} />
															</button>
															<button onClick={() => {
																handleDeleteParam(p)
															}} title={"Remover"} className={ `p-2 text-left text-sm col-span-1 text-red-500 bg-white hover:bg-red-600 h-[28px] w-[28px] hover:text-white transition flex items-center justify-center rounded`}>
																<BsTrash size={16} />
															</button>
														</div>
													</div>
												)) : <span>Sem registros</span>}
											</div>
											}
										</div>
									</>
								:
									<>
										<FormControl className="w-full flex gap-1 flex-col">
											<FormLabel className="font-bold text-sm pl-2 dark:text-white">Valor</FormLabel>
											<CurrencyInput defaultValue={selectedItem.price.toFixed(2).replace(".", ",")} onChange={(e)=> {
												const _priceTxt = e.target.value.replace(/R|\$|\./gi, "").replace(/\,/gi, ".")
												const _priceFloat = parseFloat(_priceTxt)
												const _price = isNaN(_priceFloat) ? 0 : _priceFloat
												setSelectedItem((i) => {
                                                    return {...i, price: _price }
                                                })
											}} className="h-[50px] px-2 border border-gray-600 w-full" placeholder="R$0,00" />
										</FormControl>
									</>
								}

								<div className="flex items-center justify-end gap-2">
									<button onClick={handleDeleteItem} className="bg-red-500 hover:bg-red-600 text-white rounded w-[75px] h-[50px] flex items-center justify-center text-xs">
										<span className="flex items-center gap-1">
											Remover
										</span>
									</button>
									<button onClick={handleSaveItem} className="bg-green-500 hover:bg-green-600 text-white rounded w-[75px] h-[50px] flex items-center justify-center text-xs">
										<span className="flex items-center gap-1">
											Salvar
										</span>
									</button>
								</div>
							</div>
					:
						<div className="flex h-full w-full">
                            <p className="text-sm">Selecione um item.</p>
                        </div>
					}
				</div>
            </div>


		</>
	)
}
export default Categorys
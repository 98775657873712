import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput, { MaskedInputProps } from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const CurrencyInput = ({ maskOptions, defaultValue, value, onChange, placeholder, className, onBlur }: {
    maskOptions: any,
    value?: any,
	defaultValue?: any,
    onChange?: React.ChangeEventHandler<HTMLInputElement>,
    placeholder: any,
    className: any,
	onBlur?: any
}) => {
    const defaultMaskOptions = {
        prefix: 'R$',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 2, // how many digits allowed after the decimal
        integerLimit: 15, // limit length of integer numbers
        allowNegative: false,
        allowLeadingZeroes: false,
    }

    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
        ...maskOptions,
    })

    return (
        <MaskedInput value={value} defaultValue={defaultValue} onChange={onChange} onBlur={onBlur} mask= { currencyMask } className={className} />
    )
}

CurrencyInput.defaultProps = {
    inputMode: 'numeric',
    maskOptions: {},
}

CurrencyInput.propTypes = {
    inputMode: PropTypes.string,
    maskOptions: PropTypes.shape({
        prefix: PropTypes.string,
        suffix: PropTypes.string,
        includeThousandsSeparator: PropTypes.bool,
        thousandsSeparatorSymbol: PropTypes.string,
        allowDecimal: PropTypes.bool,
        decimalSymbol: PropTypes.string,
        decimalLimit: PropTypes.string,
        requireDecimal: PropTypes.bool,
        allowNegative: PropTypes.bool,
        allowLeadingZeroes: PropTypes.bool,
        integerLimit: PropTypes.number,
    }),
}

export default CurrencyInput

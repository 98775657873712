import { replaceSpecialChars } from 'helpers/conversions';
import { useEffect, useMemo, useState } from 'react';
import { StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async'
import { useAppSelector } from 'redux/hooks';
import api from 'services/api';
import FinanceiroServicosCard from './components/FinanceiroServicosCard';
import { toast } from 'react-toastify';
import { ConfigDataType } from 'types/app';
import { toastError } from 'components/toasts/toastError';

const nf_regimes = [
	{id: "1", label: "Microempresa municipal"},
	{id: "2", label: "Estimativa"},
	{id: "3", label: "Sociedade de profissionais"},
	{id: "4", label: "Cooperativa"},
	{id: "5", label: "MEI - Simples Nacional"},
	{id: "6", label: "ME EPP - Simples Nacional"},
]

const optante_simples_nacional = [
	{id: "1", label: "Sim"},
	{id: "2", label: "Não"},
]

const ConfiguracoesFinanceiro = () => {
	const user = useAppSelector(selector => selector.user.user)
	const [data, setData] = useState({
		operacaoNF: {valueStr: "", valueNum: 0},
		optanteSimplesNacional: {valueStr: "", valueNum: 0},
		regime_especial_tributacao: {valueStr: "", valueNum: 0},
		operacaoServicos: {valueStr: "", valueNum: 0},
	})

	const [loadingOperacoes, setLoadOperacoes] = useState(true)
	const [operacoes, setOperacoes] = useState<{value: string, label: string}[]>([])

	const loadOperacoes = () => {
		setLoadOperacoes(true)
		api.get('/operacoes/autocomplete', {
			headers: {
                token: user?.token
            }
		}).then(response=> {
			setLoadOperacoes(false)
			if(response){
				if(response.data){
					return setOperacoes(response.data.map((operacao: {id: number, name: string})=>({value: operacao.id.toString(), label: operacao.name.toString()})))
				}
			}
		}).catch(()=> {
			setLoadOperacoes(false)
			return setOperacoes([])
		})
	}

	const loadData = () => {
        api.post('financeiro/get', {
			configs: "operacaoNF,optanteSimplesNacional,regime_especial_tributacao,operacaoServicos"
		}, {
            headers: {
                token: user?.token
            }
        }).then((res: {data: any})=> {
			setData(res.data)
        }).catch(err=> {
            toastError(err, false)
        })
	}

	const handleSaveNF = () => {
        api.post('financeiro/save', {
			operacaoNF: data["operacaoNF"],
			optanteSimplesNacional: data["optanteSimplesNacional"],
			regime_especial_tributacao: data["regime_especial_tributacao"],
		}, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
			toast("Configurações salvas com sucesso!", {
				type: "success",
				theme: "light"
			})
        }).catch(err=> {
            toastError(err, false)
        })
	}

	const handleSaveFinanceiro = () => {
        api.post('financeiro/save', {
			operacaoServicos: data["operacaoServicos"],
		}, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
			toast("Configurações salvas com sucesso!", {
				type: "success",
				theme: "light"
			})
        }).catch(err=> {
            toastError(err, false)
        })
	}

	const selectStyle: StylesConfig = {
		singleValue: (styles, { isDisabled }) => ({
			...styles,
			color: isDisabled ? "rgb(112 126 174 / 1)" : undefined
		}),
		input: (styles, { isDisabled }) => ({
			...styles,
			paddingLeft: 2,
			paddingRight: 2,
			height: 42,

		}),
		option: (styles, { isSelected, isFocused, isDisabled}) => ({
			...styles,
			background: isSelected ? "#2d394d" : isFocused ? "#edf2f7" : styles.background,
			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled
				? "#cbd5e0" : undefined,
			},
		}),
		control: (styles, {isDisabled}) => ({
			...styles,
			borderColor: "#a3aed0",
			borderRadius: 0,
			background: isDisabled ? "#f8f9fa" : "white",
			cursor: isDisabled ? "default" : "text"
		})
	}

	const editData = (key: keyof typeof data, valueNum?: number, valueStr?: string) => {
		setData({...data, [key]: {
			valueNum,
			valueStr
		} });
	}

	useEffect(()=> {
		loadOperacoes()
		loadData()
	}, [])

	return(
		<div className="mt-5 flex flex-col px-5">
			<div className="flex items-center justify-between">
				<div className="text-2xl font-bold text-black dark:text-white">
					Configurações Financeiras
				</div>
			</div>
			<div className="grid grid-cols-6 mt-5 gap-4 items-end">
				<div className="flex flex-col gap-1 col-span-2">
					<h2 className="text-md font-bold text-gray-800 col-span-2 flex items-center gap-2">
						Operação responsável pelos serviços prestados
						<div className="text-xs text-blue-500 border border-blue-500 rounded-full w-[16px] h-[16px] flex items-center justify-center cursor-pointer" title='Ao setar essa confiuração, a operação selecionada compartilhará os lucros/gastos de serviços nos dashboards financeiros'>
							<span className=''>?</span>
						</div>
					</h2>
					<AsyncSelect
						defaultOptions={operacoes}
						menuPlacement="auto"
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = operacoes
									.filter((item) =>
										replaceSpecialChars(
											item.label.toLowerCase()
										).includes(inputstring.toLowerCase())
									)
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={{
							value: data.operacaoServicos.valueStr,
							label: operacoes?.find(
								(c) =>
									c.value.toString() ==
									data.operacaoServicos?.valueStr.toString()
							)?.label,
						}}
						onChange={(e: any) => {
							editData("operacaoServicos", 0, e.value);
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>
				</div>
				<button onClick={handleSaveFinanceiro} className="rounded bg-blue-500 h-[50px] text-white font-bold md:max-w-[100px] text-sm">
					Salvar
				</button>
			</div>

			<div className="flex items-center justify-between mt-5">
				<div className="text-2xl font-bold text-black dark:text-white">
					Configurações Notas Fiscais
				</div>
			</div>

			<div className="grid grid-cols-6 mt-5 gap-4 items-end">
				<div className="flex flex-col gap-1 col-span-2">
					<h2 className="text-md font-bold text-gray-800 col-span-2">
						Operação emitente
					</h2>
					<AsyncSelect
						defaultOptions={operacoes}
						menuPlacement="auto"
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = operacoes
									.filter((item) =>
										replaceSpecialChars(
											item.label.toLowerCase()
										).includes(inputstring.toLowerCase())
									)
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={{
							value: data.operacaoNF.valueStr,
							label: operacoes?.find(
								(c) =>
									c.value.toString() ==
									data.operacaoNF?.valueStr.toString()
							)?.label,
						}}
						onChange={(e: any) => {
							editData("operacaoNF", 0, e.value);
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>
				</div>
				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">
						Regime tributário
					</h2>

					<AsyncSelect
						defaultOptions
						cacheOptions
						menuPlacement="auto"
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = nf_regimes
									.filter((item) =>
										replaceSpecialChars(
											item.label.toLowerCase()
										).includes(inputstring.toLowerCase())
									)
									.map((item) => {
										let _item: any = {};
										_item.value = item.id;
										_item.label = item.label;
										return _item;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={{
							value: data.regime_especial_tributacao.valueStr,
							label: nf_regimes?.find(
								(c) =>
									c.id.toString() ==
									data.regime_especial_tributacao?.valueStr.toString()
							)?.label,
						}}
						onChange={(e: any) => {
							editData("regime_especial_tributacao", 0, e.value);
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>
				</div>
				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">
						Optante pelo Simples Nacional?
					</h2>

					<AsyncSelect
						defaultOptions
						cacheOptions
						menuPlacement="auto"
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = optante_simples_nacional
									.filter((item) =>
										replaceSpecialChars(
											item.label.toLowerCase()
										).includes(inputstring.toLowerCase())
									)
									.map((item) => {
										let _item: any = {};
										_item.value = item.id;
										_item.label = item.label;
										return _item;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={{
							value: data.optanteSimplesNacional.valueStr == "1" ? "1" : "2",
							label: optante_simples_nacional?.find(
								(c) =>
									c.id.toString() ==
									(data.optanteSimplesNacional.valueStr == "1" ? "1" : "2")
							)?.label,
						}}
						onChange={(e: any) => {
							editData("optanteSimplesNacional", 0, (e.value == "1" ? "1" : "2"));
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>
				</div>

				<button onClick={handleSaveNF} className="rounded bg-blue-500 h-[50px] text-white font-bold md:max-w-[100px] text-sm">
					Salvar
				</button>
			</div>

			<div className="mt-5">
				<FinanceiroServicosCard />
			</div>
		</div>
	)
}

export default ConfiguracoesFinanceiro
export const scopeTypes = {
    planejamento: "Planejamento",
    instalacaoFisica: "InstalacaoFisica",
    instalacaoLogica: "InstalacaoLogica",
    implementacao: "Implementacao",
    homologacao: "Homologacao",
    finalizacao: "Finalizacao",
    sustentacao: "Sustentacao",
    suporte: "Suporte"
}

export const scopeNames = {
    Planejamento: "Planejamento",
    InstalacaoFisica: "Instalação Física",
    InstalacaoLogica: "Instalação Lógica",
    Implementacao: "Implementação",
    Homologacao: "Homologação",
    Finalizacao: "Finalização",
    Sustentacao: "Sustentação",
    Suporte: "Suporte"
}
const numberToHour = (num: number) : {hours: string, minutes: string} => {
    if(!num || typeof(num) != "number"){
        return {hours: null, minutes: null}
    }
    var hours : any = Math.floor(num / 60);
    if(hours.toString().length < 2) hours = "0"+hours
    var minutes : any = num % 60;
    if(minutes.toString().length < 2) minutes = "0"+minutes
    return {hours, minutes};
}

const stringToInteger = (str: any) => {
    return str ? typeof(str) == "string" ? parseInt(str) : str : undefined
}

const stringToFloat = (str: any) => {
    return str ? typeof(str) == "string" ? parseFloat(str) : str : undefined
}

const formatToCurrency = (str: any) => {
	const toConvert = parseFloat(str)
	const formatted = Intl.NumberFormat("pt-BR", {style: "currency", currency: "BRL"}).format(isNaN(toConvert) ? 0 : toConvert)
	return formatted
}

const replaceSpecialChars = (str: string) => {
    str = str.replace(/[ÀÁÂÃÄÅ]/g, "A");
    str = str.replace(/[àáâãäå]/g, "a");
    str = str.replace(/[ÈÉÊË]/g, "E");
    str = str.replace(/[èéêë]/g, "e");
    str = str.replace(/[ÌÍÎÏ]/g, "I");
    str = str.replace(/[ìíîï]/g, "i");
    str = str.replace(/[ÒÓÔÕÖ]/g, "O");
    str = str.replace(/[òóôõö]/g, "o");
    str = str.replace(/[ÙÚÛÜ]/g, "U");
    str = str.replace(/[ùúûü]/g, "u");
    str = str.replace(/[ÝŸ]/g, "Y");
    str = str.replace(/[ýÿ]/g, "y");
    str = str.replace(/[Ç]/g, "C");
    str = str.replace(/[ç]/g, "c");
    str = str.replace(/[Ñ]/g, "N");
    str = str.replace(/[ñ]/g, "n");

    // Substituindo qualquer outro caractere especial que não seja alfanumérico por nada
    return str
}

const normalizar = (str: string) => {
	return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}


const padWithLeadingZeros = (num: number, totalLength: number) => {
	return String(num).padStart(totalLength, '0');
}

export {
    numberToHour,
    stringToInteger,
    stringToFloat,
	formatToCurrency,
	padWithLeadingZeros,
	replaceSpecialChars,
	normalizar
}
import { Button, ChakraProvider, FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import React from "react"
import CurrencyInput from "views/contracts/new/components/CurrencyInput"

interface ICreateModal {
	isOpen: boolean,
    onClose: () => void,
    handleCreate: () => void,
	saveButtonText?: string,
	title?: string,
	setData: React.Dispatch<React.SetStateAction<{
		isOpen: boolean,
		data: {
			name: string,
			price: number,
			partNumber?: string
		}
	}>>,
    data: {
		name: string,
		price: number,
		partNumber?: string
	}
}

const SubItemModal = (props: ICreateModal) => {
	return(
		<ChakraProvider>
				<Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
					<ModalOverlay />
					<ModalContent className="dark:bg-navy-800">
						<ModalHeader className="dark:text-white">{props.title}</ModalHeader>
						<ModalCloseButton className="dark:text-white" />
						<ModalBody className="dark:text-white flex flex-col gap-5">
							<FormControl className="w-full">
								<FormLabel className="text-gray-600 dark:text-white">Nome</FormLabel>
								<input type="text" defaultValue={props.data.name} onChange={(e)=> {
									props.setData((val) => {
										return {...val, data: {...val.data, name: e.target.value}}
                                    })
								}} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="Nome do item" />
							</FormControl>

							<FormControl className="w-full">
								<FormLabel className="text-gray-600 dark:text-white">Part Number</FormLabel>
								<input type="text" defaultValue={props.data.partNumber} onChange={(e)=> {
									props.setData((val) => {
										return {...val, data: {...val.data, partNumber: e.target.value}}
                                    })
								}} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="PN" />
							</FormControl>

							<FormControl className="w-full flex gap-1 flex-col">
								<FormLabel className="font-bold text-gray-600 text-sm pl-2 dark:text-white">Valor</FormLabel>
								<CurrencyInput defaultValue={props.data.price.toFixed(2).replace(".", ",")} onChange={(e)=> {
									const _priceTxt = e.target.value.replace(/R|\$|\./gi, "").replace(/\,/gi, ".")
									const _priceFloat = parseFloat(_priceTxt)
									const _price = isNaN(_priceFloat) ? 0 : _priceFloat

									props.setData((i) => {
										return {
											...i,
											data: {...i.data, price: _price}
										}
									})
								}} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="R$0,00" />
							</FormControl>
						</ModalBody>

						<ModalFooter>
							<Button colorScheme='red' mr={3} onClick={props.onClose}>
								Fechar
							</Button>
							<Button onClick={props.handleCreate} colorScheme="green" variant='solid'>{props.saveButtonText ? props.saveButtonText : "Criar"}</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</ChakraProvider>
	)
}

export default SubItemModal
import { BsCalculatorFill } from "react-icons/bs"
import { IoMdContract } from "react-icons/io"
import { IoContract } from "react-icons/io5"
import { MdAdminPanelSettings, MdNote, MdNoteAlt, MdSchool, MdSlideshow, MdWeb } from "react-icons/md"
import { RiGovernmentFill } from "react-icons/ri"
import { SiAmazonaws, SiDell, SiFortinet, SiGooglesheets, SiIbm, SiIbmcloud, SiKaspersky, SiMicrosoftexcel, SiPowerbi, SiSchneiderelectric, SiTrendmicro, SiVeeam, SiVmware } from "react-icons/si"
import { TbForms, TbLetterI, TbLetterM, TbLetterP, TbLetterS, TbLetterW, TbTruckDelivery } from 'react-icons/tb'
import { TiCloudStorage } from "react-icons/ti"

interface LinkType{
    permission?: string,
    label?: string,
    links?: {
        icon?: JSX.Element,
        name: string,
        link: string
        image?: string,
    }[]
}

const links: {
    interno: LinkType,
    comum: LinkType,
    sizing: LinkType,
    powerBI: LinkType,
    backoffice: LinkType,
	formularios: LinkType,
	apresentacoes: LinkType
} = {

    interno: {
        label: "Acesso interno",
        links: [
            {
                icon: <MdSchool className="h-12 w-12" />,
                name: "Pro It Site University",
                link: "https://proit.com.br/university"
            },
            {
                icon: <MdAdminPanelSettings className="h-12 w-12" />,
                name: "Pro It Site Admin",
                link: "https://proit.com.br/admin"
            },
        ],
    },

	formularios: {
		label: "Formulários",
		links: [
            {
                icon: <SiGooglesheets className="h-12 w-12" />,
                name: "Agendamento",
                link: "https://forms.proit.com.br/agendamento/"
            },
            {
                icon: <SiGooglesheets className="h-12 w-12" />,
                name: "Avaliação de Time",
                link: "https://forms.proit.com.br/satisfacao/"
            },
            {
                icon: <SiGooglesheets className="h-12 w-12" />,
                name: "Dimensionamento de Backup",
                link: "https://forms.proit.com.br/dimensionamentobackup/"
            },
            {
                icon: <SiGooglesheets className="h-12 w-12" />,
                name: "Dimensionamento de Firewall",
                link: "https://forms.proit.com.br/dimensionamentofirewall/"
            },
            {
                icon: <SiGooglesheets className="h-12 w-12" />,
                name: "Levantamento de Informações para Proposta BC",
                link: "https://forms.proit.com.br/propostabc/"
            },
            {
                icon: <SiGooglesheets className="h-12 w-12" />,
                name: "Qualificação Pentest",
                link: "https://forms.proit.com.br/pentest/"
            },
		]
	},
	apresentacoes: {
		label: "Apresentações",
		links: [
            {
                icon: <MdSlideshow className="h-12 w-12" />,
                name: "Segurança",
                link: "https://proit.com.br/seguranca/"
            },
            {
                icon: <MdSlideshow className="h-12 w-12" />,
                name: "Portfólio",
                link: "https://proit.com.br/portfolio/"
            },
            {
                icon: <MdSlideshow className="h-12 w-12" />,
                name: "Backup",
                link: "https://proit.com.br/backup/"
            },
            {
                icon: <MdSlideshow className="h-12 w-12" />,
                name: "Business Control",
                link: "https://proit.com.br/businesscontrol/"
            },
		]
	},
    comum: {
        label: "Uso Comum",
        links: [
            {
                icon: <SiAmazonaws className="h-12 w-12" />,
                name: "AWS Partner Central",
                link: "https://partnercentral.awspartner.com/APNLogin"
            },
            {
                icon: <SiFortinet className="h-12 w-12" />,
                name: "Fortinet Partner Portal",
                link: "https://partnerportal.fortinet.com/English/"
            },
            {
                icon: <SiDell className="h-12 w-12" />,
                name: "Dell Partner Portal",
                link: "https://www.dell.com/identity/global/Login/a1e7fd82-03de-4731-8899-878cb868b8fa?c=pt&l=pt&redirecturl=https%3A%2F%2Fwww.delltechnologies.com%2Foaks%2Fsales%2Fsaml%2FSSO&feir=1"
            },
            {
                icon: <SiDell className="h-12 w-12" />,
                name: "Klue",
                link: "https://app.klue.com/?_gl=1*pvlos0*_ga*MTE3ODEzNjUzMi4xNjkxMDc1Mzk4*_ga_WXNG3BSJGZ*MTY5MTA3NTM5Ny4xLjAuMTY5MTA3NTM5Ny42MC4wLjA"
            },
            {
                icon: <MdWeb className="h-12 w-12" />,
                name: "RD Station CRM",
                link: "https://accounts.rdstation.com.br/?locale=en"
            },
            {
                icon: <SiVeeam className="h-12 w-12" />,
                name: "Veeam Pro Partner",
                link: "https://propartner.veeam.com/login"
            },
        ],
    },

    sizing: {
        label: "Sizing e Homologação",
        permission: "sizingehomolog",
        links: [
            {
                icon: <SiVmware className="h-12 w-12" />,
                name: "Guia de Homologação VMWare",
                link: "https://www.vmware.com/resources/compatibility/search.php?deviceCategory=software&details=1&partner=110&operatingSystems=58&productNames=15&page=1&display_interval=100&sortColumn=Partner&sortOrder=Asc&testConfig=16"
            },
            {
                icon: <SiMicrosoftexcel className="h-12 w-12" />,
                name: "Resultado Pentest",
                link: "https://forms.office.com/Pages/DesignPageV2.aspx?origin=NeoPortalPage&subpage=design&id=UuCrYF2JUU6kxUH0D7XyDRAG74DANHVNmTjyaXrYztpUQ01LSzA2NUpQTFYxVUs1UU9QQkZaQjNQMyQlQCN0PWcu&analysis=true"
            },
            {
                icon: <SiMicrosoftexcel className="h-12 w-12" />,
                name: "Resultado Business Control",
                link: "https://forms.office.com/Pages/DesignPageV2.aspx?origin=NeoPortalPage&subpage=design&id=UuCrYF2JUU6kxUH0D7XyDU4Z9MQq-qlLskQfgpVZgDNUN0xVTjNJTEdMODg0RE5IVktMU0o1V1dZUiQlQCN0PWcu&analysis=true"
            },
            {
                icon: <SiMicrosoftexcel className="h-12 w-12" />,
                name: "Resultado LGPD",
                link: "https://forms.office.com/Pages/DesignPageV2.aspx?origin=NeoPortalPage&subpage=design&id=UuCrYF2JUU6kxUH0D7XyDU4Z9MQq-qlLskQfgpVZgDNURjBES0dSSEgxS1BXNkpOU1YxOVBaSkhQQSQlQCN0PWcu&analysis=true"
            },
            {
                icon: <SiIbm className="h-12 w-12" />,
                name: "Interoperabilidade",
                link: "https://www-50.ibm.com/systems/support/storage/ssic/interoperability.wss"
            },
            {
                icon: <SiDell className="h-12 w-12" />,
                name: "Mid Range Sizer",
                link: "https://midrangesizer.dell.com/"
            },
            {
                icon: <SiDell className="h-12 w-12" />,
                name: "Power Sizer",
                link: "https://powersizer.dell.com/ui/index.html#/home"
            },
            {
                icon: <SiDell className="h-12 w-12" />,
                name: "Vx Rail Sizer",
                link: "https://vxrailsizer.dell.com/"
            },
        ],
    },

    powerBI: {
        label: "Power BI",
        permission: "powerbi",
        links: [
            {
                icon: <SiPowerbi className="h-12 w-12" />,
                name: "Power BI ProIT",
                link: "https://app.powerbi.com/view?r=eyJrIjoiYzZmMGVkMDMtNDliNC00ZTlmLWI3ZGUtZDI3MDI2MDYxNzJlIiwidCI6ImQ2MmUxNDg5LWU1NjktNDMyOS1hNWRmLTU2NTE5NWUyYjk1OCJ9"
            },
        ],
    },

    backoffice: {
        label: "BackOffice",
        permission: "backoffice",
        links: [
            {
                icon: <SiDell className="h-12 w-12" />,
                link: "https://www.delltechnologies.com/partner/en-us/global-site-selector.htm",
                name: "Dell",
            },
            {
                icon: <SiDell className="h-12 w-12" />,
                link: "https://partnerincentives.delltechnologies.com/Home/UserHome.aspx",
                name: "Incentivos Dell - Reembolso Eventos",
            },
            {
                icon: <SiVeeam className="h-12 w-12" />,
                link: "https://propartner.veeam.com/login",
                name: "Veeam",
            },
            {
                icon: <SiFortinet className="h-12 w-12" />,
                link: "https://partnerportal.fortinet.com/English/",
                name: "Fortinet",
            },
            {
                icon: <SiKaspersky className="h-12 w-12" />,
                link: "https://partners.kaspersky.com/s/login/?language=pt_BR&startURL=%2Fs%2F%3Flanguage%3Den_US&ec=302",
                name: "Kaspersky",
            },
            {
                icon: <SiSchneiderelectric className="h-12 w-12" />,
                link: "https://www.se.com/br/pt/",
                name: "APC",
            },
            {
                icon: <SiAmazonaws className="h-12 w-12" />,
                link: "https://partnercentral.awspartner.com/APNLogin",
                name: "AWS",
            },
            {
                icon: <SiTrendmicro className="h-12 w-12" />,
                link: "https://community-trendmicro.force.com/Gpartner/s/login/?language=pt_BR&ec=302&startURL=%2FGpartner%2Fs%2F",
                name: "Trend Micro",
            },
            {
                icon: <TbTruckDelivery className="h-12 w-12" />,
                link: "https://www.jamef.com.br/",
                name: "Rastreio Jamef",
            },
            {
                icon: <TbTruckDelivery className="h-12 w-12" />,
                link: "https://www.braspress.com/rastreie-sua-encomenda/",
                name: "Rastreio Braspress",
            },
            {
                icon: <TbTruckDelivery className="h-12 w-12" />,
                link: "https://movvi.com.br/ferramentas/rastreio",
                name: "Rastreio Movi",
            },
            {
                icon: <TbLetterS className="h-12 w-12" />,
                link: "https://revendas.scansource.com.br/loja/index.asp",
                name: "Scansource",
            },
            {
                icon: <TbLetterS className="h-12 w-12" />,
                link: "https://src.scansource.com.br/?utm_campaign=ScanSource&utm_medium=email&_hsmi=261333025&_hsenc=p2ANqtz-_ehZ3zrSU7MSQSeym71J4FFZ9EchtVTLLBrmoanG8lgOa563Rv3XJ-qnkCAm1q8chBzBi8uk1hoQNTbo7U66neEF8trQ&utm_content=261333025&utm_source=hs_email",
                name: "SRC Scansource",
            },
            {
                icon: <TbLetterS className="h-12 w-12" />,
                link: "https://rma.scansource.com.br/ ",
                name: "RMA Scansource",
            },
            {
                icon: <TbLetterI className="h-12 w-12" />,
                link: "https://br.ingrammicro.com/Site/home",
                name: "Ingram Micro",
            },
            {
                icon: <TbLetterI className="h-12 w-12" />,
                link: "https://br.ingrammicro.com/Site/Login/IdpWidget?ReturnUrl=%2Fsite%2Fcms%3Fpage%3DRedesign-Pages%2FServices-and-Support%2FSuporteESDMicrosoft%2FReenviodeLicenca",
                name: "Reenvio de Licença ESD - Ingram",
            },
            {
                icon: <TbLetterI className="h-12 w-12" />,
                link: "https://br.ingrammicro.com/site/cms?page=Redesign-Pages/Services-and-Support/RMA-(1)",
                name: "Abertura de RMA - Ingram",
            },
            {
                icon: <TbLetterI className="h-12 w-12" />,
                link: "https://www.ingrammicro.com.br/portal/comissoes-ingram-micro-abertura-de-ocorrencias/",
                name: "Ocorrência Comissões - Ingram",
            },
            {
                icon: <TbLetterP className="h-12 w-12" />,
                link: "https://app.pipefy.com/public/form/iET9X35-",
                name: "Abertura de Ocorrência - Ingram",
            },
            {
                icon: <RiGovernmentFill className="h-12 w-12" />,
                link: "https://solucoes.receita.fazenda.gov.br/Servicos/cnpjreva/Cnpjreva_Solicitacao.asp",
                name: "Consulta CNPJ",
            },
            {
                icon: <RiGovernmentFill className="h-12 w-12" />,
                link: "http://www.sintegra.gov.br/",
                name: "SINTEGRA",
            },
            {
                icon: <RiGovernmentFill className="h-12 w-12" />,
                link: "https://www.bcb.gov.br/",
                name: "Banco Central",
            },
            {
                icon: <MdNote className="h-12 w-12" />,
                link: "https://sjrp.ginfes.com.br/",
                name: "Emissões NF",
            },
            {
                icon: <BsCalculatorFill className="h-12 w-12" />,
                link: "https://www.idinheiro.com.br/calculadoras/calculadora-correcao-de-valor-por-indice/ ",
                name: "Calculadora %",
            },
            {
                icon: <TbLetterW className="h-12 w-12" />,
                link: "https://formigamg.webiss.com.br/",
                name: "Emissão RANFs",
            },
            {
                icon: <MdNoteAlt className="h-12 w-12" />,
                link: "https://app.clicksign.com/",
                name: "Assinatura de Contratos",
            },
            {
                icon: <MdWeb className="h-12 w-12" />,
                link: "https://accounts.rdstation.com.br/?redirect_to=https%3A%2F%2Fplugcrm.net%2Fauth%2Fcallback%3Fredirect_to%3D%252Fapp%252523%25252Ftasks#/tasks",
                name: "RD Station",
            },
            {
                icon: <TiCloudStorage className="h-12 w-12" />,
                link: "https://app.pipedrive.com/auth/login",
                name: "Pipedrive"
            },
        ]
    },
}

export default links